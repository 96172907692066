import React, { FC } from 'react';
import { Button } from 'components/button/Button';
import { Achievement } from 'components/achievement/Achievement';
import { home } from 'texts/texts';
import { TextWithStyle } from 'components/TextWithStyle';
import { renderTextWithLineBreaks } from 'utils/textUtils';
import './Hero.css';

export const Hero: FC = () => {

  const handleAchievementClick = () => {
    window.open('https://www.linkedin.com/company/toastie/', '_blank');
  };

  const appStoreLink = 'https://apps.apple.com/au/app/toastie-health-tracker/id6736644572';
  const appStoreSrc = `${process.env.PUBLIC_URL}/assets/appDownload/AppStore.png`;

  const playStoreLink = 'https://play.google.com/store/apps/details?id=au.toastie.health.tracker&hl=en_AU&pli=1';
  const playStoreSrc = `${process.env.PUBLIC_URL}/assets/appDownload/PlayStore.png`;

  const awsSrc = `${process.env.PUBLIC_URL}/assets/supporters/AWS-logo.png`;
  const buildersClubSrc = `${process.env.PUBLIC_URL}/assets/supporters/Builders-Club-logo.png`;
  const heroImageSrc = `${process.env.PUBLIC_URL}/assets/hero-image.svg`;
  const investmentNSWSrc = `${process.env.PUBLIC_URL}/assets/supporters/Investment-NSW-logo.png`;
  const nswGovSrc = `${process.env.PUBLIC_URL}/assets/supporters/NSW-Government-logo.png`;
  const pcosSrc = `${process.env.PUBLIC_URL}/assets/supporters/PCOS-logo.png`;
  const unswSrc = `${process.env.PUBLIC_URL}/assets/supporters/UNSW-logo.png`;

  return (
    <div className="hero-section">
      <div className="home-content-container">
        <section className='hero'>
          <div className='hero-text'>
            <h1>
              {home.welcomeMessage.map((part, index) => (
                <TextWithStyle key={index} text={part.text} style={part.style} />
              ))}
            </h1>
            <div>
              <p>{home.catchphrase}</p>
              <p>{home.introduction}</p>
            </div>
            <div className="download-buttons">
              <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={appStoreSrc}
                  alt="Download on the App Store"
                  className="download-button"
                />
              </a>
              <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={playStoreSrc}
                  alt="Get it on Google Play"
                  className="download-button"
                />
              </a>
            </div>
          </div>
          <div className='hero-image'>
            <img src={heroImageSrc} alt='Hero' />
          </div>
        </section>

        <section className='achievements'>
          {home.achievements.map((achievement, index) => (
            <Achievement
              key={index}
              title={achievement.title}
              description={achievement.description}
              onClick={handleAchievementClick}
            />
          ))}
        </section>

        <section className='supporters'>
          <p>{home.supporters}</p>
          <div>
            <img src={unswSrc} alt='UNSW' />
            <img src={nswGovSrc} alt='NSW government' />
            <img src={investmentNSWSrc} alt='Investment NSW' />
            <img src={awsSrc} alt='AWS' />
            <img src={buildersClubSrc} alt='The Builders Club' />
            <img src={pcosSrc} alt='PCOS Awareness Association' />
          </div>
        </section>
      </div>
    </div>
  )
};
